<template>
	<div class="search">
		<div class="top">
			<div class="name">Search</div>
			<div class="line"></div>
		</div>
		<div class="content">
			<form @submit.prevent="submit">
				<TextInput
					id="query"
					v-model.trim="state.query"
					type="text"
					maxlength="255"
					autocomplete="off"
					label="Query:"
					placeholder="name@example.com"
					:form="state.form"
					:required="true"
					:autofocus="true"
					tabindex="1"
				/>
			</form>
			<div class="loading" v-if="state.form.loading">
				<div class="spinner">
					<div class="bounce1"></div>
					<div class="bounce2"></div>
					<div class="bounce3"></div>
				</div>
			</div>
			<div class="results" v-if="!state.form.loading && state.licenses.length > 0">
				Found <span class="count">{{ state.licenses.length }}</span> licenses:
			</div>
			<div class="licenses" v-if="!state.form.loading">
				<TableView
					class="license"
					v-for="license in state.licenses"
					:key="license.id"
					:label="statusLabel(license)"
					:labelColor="statusColor(license)"
					:scrollable="false"
				>
					<template v-slot:header>
						{{ license.product.name }}<span class="license-id">#{{ license.id }}</span>
					</template>
					<template v-slot:content>
						<TableRow label="Key:" :value="license.key">
							<ActionButton class="edit-button" :small="true" @click="copyToClipboard(license.key)"
								>Copy</ActionButton
							>
						</TableRow>
						<TableRow label="E-mail:" :value="license.email">
							<ActionButton class="edit-button" :small="true" @click="editEmail(license)"
								>Edit</ActionButton
							>
						</TableRow>
						<TableRow label="Customer name:" :value="license.customer_name" />
						<TableRow label="Seats:" :value="license.seats">
							<ActionButton class="edit-button" :small="true" @click="editSeats(license)"
								>Edit</ActionButton
							>
						</TableRow>
						<TableRow label="Price:" :value="`$${license.price / 100}`" />
						<TableRow label="Activations:" :value="`${license.activations} / ${license.max_activations}`" />
						<TableRow label="Instances:" :value="`${license.instances.length} / ${license.seats}`" />
						<TableRow label="Source:" :value="capitalize(license.source)" />
						<TableRow label="Discount code:" :value="license.discount_code" v-if="license.discount_code" />
						<TableRow label="Receipt:" v-if="receiptLink(license)">
							<ActionButton :small="true" @click="copyToClipboard(receiptLink(license))"
								>Copy receipt link</ActionButton
							>
						</TableRow>
						<TableRow label="Reset activations:">
							<ActionButton :small="true" @click="reset(license)">Reset activations</ActionButton>
						</TableRow>
						<TableRow label="Mark as refund:">
							<ActionButton :small="true" variant="orange" @click="markRefund(license)">{{
								!license.refund ? 'Mark as refunded' : 'Undo refund'
							}}</ActionButton>
						</TableRow>
						<TableRow
							label="Refund payment:"
							v-if="!license.refund && (license.source === 'gumroad' || license.source === 'paddle')"
						>
							<ActionButton :small="true" variant="warning" @click="refund(license)"
								>Refund payment</ActionButton
							>
						</TableRow>
						<TableRow label="Revoke:">
							<ActionButton :small="true" variant="warning" @click="revoke(license)">{{
								!license.revoked ? 'Revoke license' : 'Undo revoke'
							}}</ActionButton>
						</TableRow>
						<TableRow label="Split into individual keys:" v-if="license.seats > 1 && !license.revoked">
							<ActionButton :small="true" variant="warning" @click="splitKeys(license)"
								>Split keys</ActionButton
							>
						</TableRow>
						<TableRow
							label="Updates expire at:"
							:value="formatDate(license.updates_expire_at)"
							:subvalue="fromNow(license.updates_expire_at)"
							v-if="license.updates_expire_at"
						>
							<ActionButton class="edit-button" :small="true" @click="editExpire(license)"
								>Edit</ActionButton
							>
						</TableRow>
						<TableRow label="Updates renew link:" v-if="license.updates_expire_at">
							<ActionButton class="edit-button" :small="true" @click="copyToClipboard(renewLink(license))"
								>Copy renew link</ActionButton
							>
						</TableRow>
						<TableRow label="Updates expire:" value="Never" v-if="!license.updates_expire_at">
							<ActionButton class="edit-button" :small="true" @click="editExpire(license)"
								>Edit</ActionButton
							>
						</TableRow>
						<TableRow
							label="Created at:"
							:value="formatDate(license.created_at)"
							:subvalue="fromNow(license.created_at)"
						/>
						<TableRow
							label="Updated at:"
							:value="formatDate(license.updated_at)"
							:subvalue="fromNow(license.updated_at)"
						/>
						<TableRow class="instances-row" label="Instances:" v-if="license.instances.length > 0">
							<div class="instances">
								<div class="instance" v-for="instance in license.instances" :key="instance.id">
									<div class="instance-type">
										{{ instance.hostname }}
									</div>
									<div class="instance-details">
										App:
										<span class="bold">{{ instance.app }}</span
										>, macOS:
										<span class="bold">{{ instance.os }}</span>
									</div>
									<div class="instance-date">
										<span class="relative-date">({{ fromNow(instance.updated_at) }})</span
										>{{ formatDate(instance.updated_at) }}
									</div>
								</div>
							</div>
						</TableRow>
						<TableRow class="events-row" label="Events:" v-if="license.events.length > 0">
							<div class="events">
								<div class="event" v-for="event in license.events" :key="event.id">
									<div class="event-type">
										{{ event.type }}
									</div>
									<div class="event-details">
										{{ JSON.stringify(event.details) }}
									</div>
									<div class="event-date" :title="formatDate(event.created_at)">
										{{ fromNow(event.created_at) }}
									</div>
								</div>
							</div>
						</TableRow>
					</template>
				</TableView>
			</div>
		</div>
	</div>
</template>

<script setup>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '../api'
import FormHandler from '../helpers/FormHandler'
import TextInput from '../components/TextInput.vue'
import TableView from '../components/TableView.vue'
import TableRow from '../components/TableRow.vue'
import ActionButton from '../components/ActionButton.vue'
import { copyToClipboard } from '../helpers/utils'
import { reactive } from 'vue'

dayjs.extend(relativeTime)

const state = reactive({ query: '', licenses: [], form: new FormHandler() })

const submit = () => {
	state.form
		.submit('dashboard/search', {
			query: state.query,
		})
		.then(({ data }) => {
			state.licenses = data.licenses
		})
		.catch((error) => {
			console.log(error)
		})
}
const revoke = (license) => {
	if (!confirm(`Do you really want to change revoke status of the license #${license.id}?`)) {
		return
	}
	api.post(`dashboard/license/${license.id}/revoke`)
		.then(() => {
			license.revoked = !license.revoked
		})
		.catch((error) => {
			console.log(error)
		})
}
const markRefund = (license) => {
	if (!confirm(`Do you really want to change refund status of the license #${license.id}?`)) {
		return
	}
	api.post(`dashboard/license/${license.id}/mark-refund`)
		.then(() => {
			license.refund = !license.refund
		})
		.catch((error) => {
			console.log(error)
		})
}
const refund = (license) => {
	if (
		!confirm(
			`Do you really want to refund the payment for license #${license.id}?\nE-mail: ${license.email}\nProduct: ${license.product.name}\nWARNING: This action cannot be reversed.`
		)
	) {
		return
	}
	api.post(`dashboard/license/${license.id}/refund`)
		.then(() => {
			license.refund = true
		})
		.catch((error) => {
			console.log(error)
		})
}
const reset = (license) => {
	api.post(`dashboard/license/${license.id}/reset`)
		.then(() => {
			license.activations = 0
		})
		.catch((error) => {
			console.log(error)
		})
}
const editEmail = (license) => {
	const email = prompt(`Change email of license #${license.id}:`, license.email)
	if (email) {
		api.post(`dashboard/license/${license.id}/email`, {
			email: email,
		})
			.then(() => {
				license.email = email
			})
			.catch((error) => {
				console.log(error)
			})
	}
}
const editSeats = (license) => {
	const seats = parseInt(prompt(`Change number of seats for license #${license.id}:`, license.seats))
	if (seats) {
		api.post(`dashboard/license/${license.id}/seats`, {
			seats: seats,
		})
			.then(() => {
				license.seats = seats
			})
			.catch((error) => {
				console.log(error)
			})
	}
}
const editExpire = (license) => {
	let defaultValue = dayjs().format('YYYY-MM-DD 00:00:00')
	if (license.updates_expire_at) {
		defaultValue = license.updates_expire_at
	}
	const expire = prompt(`Change when updates expire for license #${license.id}:`, defaultValue)
	if (expire !== null) {
		const valueToSubmit = expire || null
		api.post(`dashboard/license/${license.id}/expire`, {
			updates_expire_at: valueToSubmit,
		})
			.then(() => {
				license.updates_expire_at = valueToSubmit
			})
			.catch((error) => {
				console.log(error)
			})
	}
}
const splitKeys = (license) => {
	if (
		!confirm(
			`Do you really want to split license #${license.id} into individual keys?\nE-mail: ${license.email}\nProduct: ${license.product.name}\n`
		)
	) {
		return
	}
	api.post(`dashboard/license/${license.id}/split-keys`)
		.then(({ data }) => {
			copyToClipboard(data.keys.join('\n'))
			license.revoked = true
		})
		.catch((error) => {
			console.log(error)
		})
}

const statusLabel = (license) => {
	if (license.chargeback) {
		return 'Chargeback'
	} else if (license.refund) {
		return 'Refunded'
	} else if (license.revoked) {
		return 'Revoked'
	} else {
		return 'Active'
	}
}

const statusColor = (license) => {
	if (license.chargeback) {
		return '#d98d0c'
	} else if (license.refund) {
		return '#DD9A36'
	} else if (license.revoked) {
		return '#b41616'
	} else {
		return '#22ad73'
	}
}
const capitalize = (s) => {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}

const formatDate = (d) => {
	return dayjs(d).format('DD.MM.YYYY [at] HH:mm:ss')
}

const fromNow = (d) => {
	return dayjs(d).fromNow()
}

const receiptLink = (license) => {
	const numberOfReceipts = license.receipts.length
	if (numberOfReceipts > 0) {
		return license.receipts[numberOfReceipts - 1].url
	}
	return null
}

const renewLink = (license) => {
	return `https://licenses.maketheweb.io/renew?key=${license.key}`
}
</script>

<style lang="scss" scoped>
.top {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.name {
	@include font-h800;
	color: #ffffff;
}

.line {
	height: 2px;

	flex-grow: 1;
	margin-left: 45px;

	background: $color-grayscale-70;
}

.content {
	max-width: 700px;
}

.results {
	margin-top: 20px;

	font-size: 18px;
	color: $color-grayscale-20;

	.count {
		color: #ffffff;
	}
}

.license-id {
	margin-left: 8px;

	font-weight: $regular;
	color: $color-grayscale-20;
}

.licenses {
	margin-top: 25px;
}

.license {
	margin-bottom: 50px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.edit-button {
	margin-left: 15px;
}

.events-row {
	height: auto;

	display: block;
	padding: 15px 25px;
}

.events {
	margin-top: 10px;
	margin-bottom: 5px;
}

.event {
	display: flex;
	align-items: center;
	margin-bottom: 12px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.event-type {
	padding: 5px 7px;
	margin-right: 10px;

	font-size: 11px;
	text-transform: uppercase;
	color: #ffffff;
	letter-spacing: 0.8px;
	background-color: $color-grayscale-50;
	border-radius: 5px;
}

.event-details {
	flex-shrink: 1;
	margin-right: 10px;

	font-size: 12px;
	font-family: monospace;
	color: $color-grayscale-20;
	letter-spacing: 0.8px;
	word-break: break-all;
}

.event-date {
	margin-left: auto;

	font-size: 14px;
	font-weight: $medium;
	color: #ffffff;
}

.relative-date {
	margin-right: 6px;

	color: $color-grayscale-20;
}

.instances-row {
	height: auto;

	display: block;
	padding: 15px 25px;
}

.instances {
	margin-top: 10px;
	margin-bottom: 5px;
}

.instance {
	display: flex;
	align-items: center;
	margin-bottom: 12px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.instance-type {
	padding: 5px 7px;
	margin-right: 10px;

	font-size: 11px;
	text-transform: uppercase;
	color: #ffffff;
	letter-spacing: 0.8px;
	background-color: $color-grayscale-50;
	border-radius: 5px;
}

.instance-details {
	flex-shrink: 1;
	margin-right: 10px;

	font-size: 14px;
	color: $color-grayscale-20;
	letter-spacing: 0.8px;
	word-break: break-all;

	.bold {
		font-weight: $medium;
		color: $color-grayscale-05;
	}
}

.instance-date {
	margin-left: auto;

	font-size: 14px;
	font-weight: $medium;
	color: #ffffff;
}

.loading {
	width: 100%;
	height: 300px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	width: 70px;
	text-align: center;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: $color-grayscale-50;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
</style>
